<!-- <svelte:options immutable={true} /> -->

<script lang="ts">
  import * as THREE from 'three';
  import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
  import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
  import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
  import type { Segment, TileWrapper } from "src/model/";
  import type WebGLRenderer from "src/model/WebGLRenderer";
  import { onMount, beforeUpdate } from "svelte";
  import { downloadFurniture } from "src/services/api";
  import { convertXML } from 'simple-xml-to-json';
  import { TILE_TRANSFORM_SCALE } from 'src/global/variable';
  import Button from "./base/Button.svelte";
  import Input from "./base/Input.svelte";
  import JSZip from 'jszip';
  import Room from "src/model/tile/Room";
  import { debounce, throttle, isEqual } from "lodash";
  import { getShapeId } from "src/helpers";

  interface geometryDesc {
    id: number;
    positions: number[];
    normals: number[];
    uv: number[];
    faces: number[];
  }

  let scene: THREE.Scene;
  let camera: THREE.OrthographicCamera;
  let renderer: THREE.WebGLRenderer;
  let composer: EffectComposer;
  let outlinePass: OutlinePass;
  let furnituresGroup: THREE.Group;
  let textInput: string;
  let frustumSize = 10;
  let outlineThickness = 1;

  function initScene() {

    // resolution = 2;
    scene = new THREE.Scene();

    const aspect = window.innerWidth / window.innerHeight;
    camera = new THREE.OrthographicCamera(frustumSize * aspect / -2, frustumSize * aspect / 2, frustumSize / 2, frustumSize / -2, 0.1, 20);
    camera.position.z = 10;
    // camera.zoom = TILE_TRANSFORM_SCALE;

    const container = document.getElementById('container');
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, preserveDrawingBuffer: true });
    renderer.setClearColor(0x000000, 0);
    // renderer.setClearColor(new THREE.Color(0xffffff));
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setAnimationLoop(animate);
    container.appendChild(renderer.domElement);

    composer = new EffectComposer(renderer);
    const renderPass = new RenderPass(scene, camera);
    composer.addPass(renderPass)
  
    outlinePass = new OutlinePass(new THREE.Vector2(4000, 4000), scene, camera);
    composer.addPass(outlinePass);

    outlinePass.edgeStrength = 10;
    outlinePass.edgeThickness = outlineThickness;
    outlinePass.visibleEdgeColor.set(0x000000);
    outlinePass.hiddenEdgeColor.set(0x000000);
    outlinePass.overlayMaterial.blending = THREE.CustomBlending;

    const light = new THREE.AmbientLight(0xaaaaaa); // soft white light
    scene.add(light);
    const directionalLight = new THREE.PointLight(0xffffff);
    directionalLight.position.set(10, 10, 10);
    scene.add(directionalLight);

    // download("bl4-17");
  }

  function isInFrustum() {

    camera.updateMatrixWorld();
    furnituresGroup.updateMatrixWorld(true);

    let frustum = new THREE.Frustum();
    const matrix = new THREE.Matrix4().multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);

    frustum.setFromProjectionMatrix(matrix);

    const box = new THREE.Box3().setFromObject(furnituresGroup);

    const points = [
      new THREE.Vector3(box.min.x, box.min.y, box.min.z),
      new THREE.Vector3(box.min.x, box.min.y, box.max.z),
      new THREE.Vector3(box.min.x, box.max.y, box.min.z),
      new THREE.Vector3(box.min.x, box.max.y, box.max.z),
      new THREE.Vector3(box.max.x, box.min.y, box.min.z),
      new THREE.Vector3(box.max.x, box.min.y, box.max.z),
      new THREE.Vector3(box.max.x, box.max.y, box.min.z),
      new THREE.Vector3(box.max.x, box.max.y, box.max.z),
    ];

    for (let i = 0; i < points.length; i++)
    {
      if (!frustum.containsPoint(points[i]))
        return false;
    }

    return true;
  }

  function expandFrustum(expansionFactor: number) {

    const aspect = window.innerWidth / window.innerHeight;

    frustumSize += expansionFactor;
    camera.left = -frustumSize * aspect / 2;
    camera.right = frustumSize * aspect / 2;
    camera.top = frustumSize / 2;
    camera.bottom = -frustumSize / 2;

    camera.updateProjectionMatrix();
  }

  function resetFrustum() {

    const aspect = window.innerWidth / window.innerHeight;

    frustumSize = 10;
    camera.left = -frustumSize * aspect / 2;
    camera.right = frustumSize * aspect / 2;
    camera.top = frustumSize / 2;
    camera.bottom = -frustumSize / 2;

    camera.updateProjectionMatrix();
  }

  function animate() {

    if (furnituresGroup !== undefined)
    {
      // furnituresGroup.rotation.x += 0.01;
      // furnituresGroup.rotation.y += 0.01;
    }

    composer?.render();
  }

  async function getFurnitureScreenshots() {

    if (textInput === undefined)
      return;

    const furnituresSlug = textInput.split(',');

    for (let slug of furnituresSlug)
    {
      let success = await download(slug);

      if (!success)
      {
        console.log(slug + " not found");
        continue;
      }

      let screenshots: { front?: string, top?: string, right?: string, left?: string } = {};
      let link = document.createElement('a');

      while (!isInFrustum())
      {
        console.log("Expand frustum");
        expandFrustum(1);
      }
      animate();
      screenshots.front = await acquireScreenshot();
      link.href = screenshots.front;
      link.download = slug + '_front_screenshot.png';
      link.click();

      furnituresGroup.rotateX(THREE.MathUtils.degToRad(90));
      resetFrustum();
      while (!isInFrustum())
      {
        console.log("Expand frustum");
        expandFrustum(1);
      }
      animate();
      screenshots.top = await acquireScreenshot();
      link.href = screenshots.top;
      link.download = slug + '_top_screenshot.png';
      link.click();
      
      furnituresGroup.rotateX(THREE.MathUtils.degToRad(-90));
      furnituresGroup.rotateY(THREE.MathUtils.degToRad(-90));
      resetFrustum();
      while (!isInFrustum())
      {
        console.log("Expand frustum");
        expandFrustum(1);
      }
      animate();
      screenshots.right = await acquireScreenshot();
      link.href = screenshots.right;
      link.download = slug + '_right_screenshot.png';
      link.click();

      furnituresGroup.rotateY(THREE.MathUtils.degToRad(180));
      resetFrustum();
      while (!isInFrustum())
      {
        console.log("Expand frustum");
        expandFrustum(1);
      }
      animate();
      screenshots.left = await acquireScreenshot();
      link.href = screenshots.left;
      link.download = slug + '_left_screenshot.png';
      link.click();
    }
  }

  async function acquireScreenshot(): Promise<string> {

    return new Promise(resolve => {

      const box = new THREE.Box3().setFromObject(furnituresGroup);

      // Get the vertices of the bounding box
      const vertices = [
          new THREE.Vector3(box.min.x, box.min.y, box.min.z),
          new THREE.Vector3(box.min.x, box.min.y, box.max.z),
          new THREE.Vector3(box.min.x, box.max.y, box.min.z),
          new THREE.Vector3(box.min.x, box.max.y, box.max.z),
          new THREE.Vector3(box.max.x, box.min.y, box.min.z),
          new THREE.Vector3(box.max.x, box.min.y, box.max.z),
          new THREE.Vector3(box.max.x, box.max.y, box.min.z),
          new THREE.Vector3(box.max.x, box.max.y, box.max.z),
      ];

      // Project the bounding box vertices onto the screen
      const screenCoords = vertices.map(vertex => {
          const vector = vertex.clone().project(camera);
          return {
              x: Math.round((vector.x + 1) * 0.5 * renderer.domElement.width),
              y: Math.round((-vector.y + 1) * 0.5 * renderer.domElement.height)
          };
      });

      // Find the minimum and maximum bounds of the screen coordinates
      let minX = Math.min(...screenCoords.map(coord => coord.x));
      let maxX = Math.max(...screenCoords.map(coord => coord.x));
      let minY = Math.min(...screenCoords.map(coord => coord.y));
      let maxY = Math.max(...screenCoords.map(coord => coord.y));

      // Add +3 to the outline thickness otherwise it's not included completely
      minX -= outlineThickness + 3;
      maxX += outlineThickness + 3;
      minY -= outlineThickness + 3;
      maxY += outlineThickness + 3;

      const width = maxX - minX;
      const height = maxY - minY;

      // Capture the entire canvas as an image
      const screenshotDataURL = renderer.domElement.toDataURL('image/png');

      // Create an image to manipulate the canvas
      const img = new Image();
      img.src = screenshotDataURL;
      img.onload = function() {
          // Create a canvas to crop the image
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');

          // Crop the part of the image that contains the object
          ctx.drawImage(
              img,
              minX, minY, width, height, // Area to crop
              0, 0, width, height        // Size on the new canvas
          );

          const croppedDataURL = canvas.toDataURL('image/png');

          resolve(croppedDataURL);
      };
    });
  }

  async function download(slug: string) {

    return await downloadFurniture(slug)
      .then(
        async res => {

          if (res?.notFound === true)
            return false;

          let zipFile: JSZip = new JSZip();
          const blob = new Blob([res]);

          return await zipFile.loadAsync(blob)
            .then(async (zip) => {
              let furniture = {
                model: await zip.file("model.xml").async("string"),
                geometry: await zip.file("geometryData.txt").async("string")
              };

              parseFurniture(furniture);

              // for(const child of furnituresGroup?.children)
              //   outlinePass.selectedObjects.push(child);

              outlinePass.selectedObjects = [...furnituresGroup?.children];

              scene.add(furnituresGroup);

              return true;
            });
        },
        res => {
          return false;
        }
      )
  }

  function addFurnitureMesh(geo: geometryDesc) {

    const geometry = new THREE.BufferGeometry();
    const pos = new THREE.BufferAttribute(
      new Float32Array(geo.positions),
      3, // 3 numbers for every item in the buffer attribute ( x, y, z)
    );
    const norm = new THREE.BufferAttribute(
      new Float32Array(geo.normals),
      3, // 3 numbers for every item in the buffer attribute ( x, y, z)
    );
    geometry.setAttribute('position', pos);
    geometry.setAttribute('normal', norm);
    //geometry.computeVertexNormals();
    geometry.setIndex(geo.faces);
    // const material = new THREE.MeshPhongMaterial({
    //     color: 0x00ff00,
    //     //wireframe: true,
    // })
    const material = new THREE.MeshStandardMaterial({
      color: 0xFFFFFF,
      //transparent: true,
      //opacity: 0.2,
      //wireframe: true,
      // depthTest: false
    });

    const furniture = new THREE.Mesh(geometry, material);
    furniture.renderOrder = 1;
    furnituresGroup.add(furniture);
  };

  function parseFurniture(newFurniture: { model: string; geometry: string }) {

    let jsonGeo: geometryDesc[] = [];

    const model = newFurniture.model;
    const geometry = newFurniture.geometry;
    // const data = JSON.parse(xml2json(m, {compact: true, spaces: 4}));
    const data = convertXML(model);
    // console.log(data);
    const splitLines = (str: string) => str.split(/\r?\n/);
    const arrayGeometry = geometry.split('GEOMETRY ');
    arrayGeometry.splice(0, 1);
    for (const geo of arrayGeometry)
    {
      if (geo.startsWith('####')) continue;

      const temp = geo.split('Positions');
      const geoData = splitLines(temp[0]);
      const geoPos = [];
      const geoNorm = [];
      const geoUVs = [];
      const geoFaces = [];

      const parseValues = (input: string, factor = 1) => {
        const regex = /:(-?\d+(?:\.\d+)?)/g;
        const matches = [];
        let match;
        while ((match = regex.exec(input)) !== null) {
          const n = parseFloat(match[1]) / factor;
          matches.push(n);
        }
        return matches;
      };

      for (const p of splitLines(temp[1]))
      {
        if (p.startsWith('p'))
        {
          const input = p.split('p ')[1];
          geoPos.push(...parseValues(input, 10));
        }
        else if (p.startsWith('n'))
        {
          const input = p.split('n ')[1];
          geoNorm.push(...parseValues(input));
        }
        else if (p.startsWith('u'))
        {
          const input = p.split('u ')[1];
          geoUVs.push(...parseValues(input));
        }
        else if (p.startsWith('f'))
        {
          const input = p.split('f ')[1];
          const regex = /\[(\d+(?:;\d+)*)\]/;
          const match = input.match(regex);
          if (match)
          {
            const t = match[1].split(';').map(Number);
            geoFaces.push(...[t[0] - 1, t[1] - 1, t[2] - 1]);
          }
        }
      }

      let geometry: geometryDesc = {
        id: parseInt(geoData[0]),
        positions: geoPos,
        normals: geoNorm,
        uv: geoUVs,
        faces: geoFaces,
      };
      jsonGeo.push(geometry);
    }

    if (furnituresGroup === undefined) furnituresGroup = new THREE.Group();
    //one furniture at a time
    else {
      furnituresGroup.clear();
      furnituresGroup.removeFromParent();
      furnituresGroup = new THREE.Group();
    }

    for (const g of jsonGeo)
      addFurnitureMesh(g);

    // geometry.computeBoundingBox();
    const boundingBox = new THREE.Box3().setFromObject(furnituresGroup);
    // boundingBox.copy(geometry.boundingBox);//.applyMatrix4( mesh.matrixWorld );

    let boundingBoxSize: THREE.Vector3 = new THREE.Vector3();
    boundingBox.getSize(boundingBoxSize);

    // if ((window as any).bounding === true) {
    //   // add 0.001 to each vector component to avoid z-fighting and to have the bounding box slightly bigger for hovering
    //   const boundingBoxGeometry = new THREE.BoxGeometry(
    //     boundingBoxSize.x + 0.001,
    //     boundingBoxSize.y + 0.001,
    //     boundingBoxSize.z + 0.001,
    //   );
    //   const boundingBoxMaterial = new THREE.MeshBasicMaterial({
    //     color: 0xff0000,
    //     //wireframe: true,
    //     depthTest: false,
    //   });

    //   const boundingBoxMesh = new THREE.Mesh(boundingBoxGeometry, boundingBoxMaterial);
    //   furnituresGroup.add(boundingBoxMesh);
    // }
    let width: string = data.model.width;
    if (width.startsWith('I') || width.startsWith('L'))
      width = width.substring(width.indexOf(':') + 1);

    let height: string = data.model.height;
    if (height.startsWith('I') || height.startsWith('L'))
      height = height.substring(height.indexOf(':') + 1);

    let depth: string = data.model.depth;
    if (depth.startsWith('I') || depth.startsWith('L'))
      depth = depth.substring(depth.indexOf(':') + 1);

    furnituresGroup.scale.set(
      +width / TILE_TRANSFORM_SCALE / boundingBoxSize.x,
      +height / TILE_TRANSFORM_SCALE / boundingBoxSize.y,
      +depth / TILE_TRANSFORM_SCALE / boundingBoxSize.z,
    );
  }

  function handleInput (e) {

    textInput = e.target.value.length > 0 ? e.target.value : undefined;
  }

  window.onresize = function () {

    const aspect = window.innerWidth / window.innerHeight;

    camera.left = -frustumSize * aspect / 2;
    camera.right = frustumSize * aspect / 2;
    camera.top = frustumSize / 2;
    camera.bottom = -frustumSize / 2;

    camera.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);
    composer.setSize(window.innerWidth, window.innerHeight);
  };

  onMount(async () => {
    initScene();
  })

</script>

<div class="absolute" id="container" />
<div class="fixed items-center bottom-4 w-full grid grid-cols-3 gap-4 pr-4 pl-4">
  <Input type="search" on:input={handleInput} class="col-span-2" fullWidth roundedFull/>
  <Button variant="secondary" class="col-span-1" on:click={getFurnitureScreenshots} title="Acquire screenshots" />
</div>
